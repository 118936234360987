<template>
  <v-main class="ma-4">
    <v-card elevation="4" width="60em" max-width="90%" class="ma-8">
      <v-card-title>お知らせ</v-card-title>
      <v-divider></v-divider>
      <v-list height="15em" style="overflow-y: auto">
        <v-list-item v-for="n in 20" :key="n"
          >2024年03月30日: お知らせ {{ n }}</v-list-item
        >
      </v-list>
    </v-card>
  </v-main>
</template>
